import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Layout from "../components/layout";
import ScrollSection from "../components/scroll-section/scroll-section";
import Seo from "../components/seo/seo";
import Hero from "../components/hero/hero";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import { URLS } from "../config";
import ArticleList from "../components/article-list/article-list";

class ContientPageTemplate extends React.Component {
  render() {
    const posts = get(this.props, "data.allContentfulPost.edges");
    const category = get(this.props, "data.contentfulCountry");
    const continents = get(this, "props.data.allContentfulContinent.edges");
    const { title, subtitle, image } = category;

    return (
      <Layout
        classNames={{ container: "bg-red-lightest" }}
        continents={continents}
        location={this.props.location}
        status={this.props.transitionStatus}
      >
        <Seo
          type="page"
          content={{
            title: `${title} Travel`,
            seoDescription: `${title} Travel`,
          }}
        />
        <ScrollSection>
          <Hero
            pretitle={{
              title: this.props.pageContext.parent.title.toUpperCase(),
              url: `${URLS.Archive}${this.props.pageContext.parent.slug}/`,
            }}
            title={title.toUpperCase()}
            type="default"
            subtitle={subtitle}
            image={image}
            svg={this.props.pageContext.parent.slug}
          />
        </ScrollSection>
        <ScrollSection>
          <ArticleList type="country" posts={posts} />
          <Breadcrumb
            className="mt-20 mb-2"
            items={[
              {
                key: "id_1",
                link: URLS.Archive,
                title: "Travel Stories",
              },
              {
                key: "id_2",
                link: `${URLS.Archive}${this.props.pageContext.parent.slug}/`,
                title: this.props.pageContext.parent.title,
              },
            ]}
            current={title}
          />
        </ScrollSection>
      </Layout>
    );
  }
}

export default ContientPageTemplate;

export const pageQuery = graphql`
  query SubCategoryQuery($id: String!) {
    contentfulCountry(id: { eq: $id }) {
      id
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          width: 424
          height: 212
        )
      }
      continent {
        title
        slug
      }
      slug
      title
      subtitle
      seoDescription
    }
    allContentfulContinent(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          title
          slug
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
        }
      }
    }
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      filter: { country: { id: { eq: $id } } }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate
          minuteRead
          heroImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
          excerpt {
            excerpt
            childMarkdownRemark {
              html
            }
          }
          author {
            displayTitle
            firstname
            surname
            location
            slug
          }
          country {
            slug
            title
            continent {
              title
              slug
            }
          }
        }
      }
    }
  }
`;
